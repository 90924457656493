//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Modal, Trans } from '../..';
import { TxButton, TxButtonMode } from 'texkit-ui';
import { TxPaymentCardForm, TxPaymentCardFormMode } from 'texkit-ui/forms';

// Actions
import { getPaymentAccounts } from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';

import './add-payment-account-modal.scss';

const AddPaymentAccountModal = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('about:blank');
  const { selectedLanguage } = useSelector(state => ({
    modal: state.modal || {},
    selectedLanguage: state.language.selected,
  }));

  const handleSuccess = () => {
    dispatch(getPaymentAccounts());
    dispatch(closeModal('AddPaymentAccountModal'));
  };

  return (
    <Modal
      name="AddPaymentAccountModal"
      className="add-payment-account-modal"
      disableOnClickOutside="true"
    >
      <h2 className="h1 modal-title">
        <Trans file="Payment" id="AddCardTitle" />
      </h2>
      <TxPaymentCardForm
        acceptedCards={['VISA', 'MasterCard', 'Discover', 'American Express']}
        billingAddressTitle={<Trans file="Payment" id="BillingAddressTitle" />}
        iFrameUrl="about:blank"
        mode={TxPaymentCardFormMode.AccountSettingsAdd}
        onSubmit={() => {}}
        onTokenReceived={() => {}}
        paymentMethodTitle={<Trans file="Payment" id="PaymentMethodTitle" />}
        spanish={selectedLanguage === 'es'}
        renderFormActions={() => (
          <TxButton mode={TxButtonMode.Primary} type="submit">
            <Trans file="Payment" id="SavePaymentMethod" />
          </TxButton>
        )}
      />
    </Modal>
  );
};

export default AddPaymentAccountModal;
